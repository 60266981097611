import { createListCollection } from "@chakra-ui/react";
import countryCodes from "country-codes-list";

const countryList = countryCodes.all();
const countries = countryList.sort((a, b) =>
  a.countryNameEn.toLowerCase() < b.countryNameEn.toLowerCase() ? -1 : 1
);

export const roles = ["Admin", "Manager", "Employee"];

export const genders = ["Male", "Female"];

export const periods = ["Month", "Year"];

export const venueTypes = ["Cafe", "Restaurant", "Bar", "Hotel", "Other"];

export const contactMehtods = ["Phone", "Email"];

export const contactPeriods = ["Morning", "Afternoon", "Evening"];

export const revenueTypes = [
  "I am not sure",
  "Under 100k",
  "100k-200k",
  "200k-500k",
  "Over 500k",
];

export const leadStatuses = ["New", "In progress", "Sign up", "Archived"];

export const collectionRoles = createListCollection({
  items: roles.map((item) => ({
    value: item,
    label: item,
  })),
});

export const collectionGenders = createListCollection({
  items: genders.map((item) => ({
    value: item,
    label: item,
  })),
});

export const collectionPeriods = createListCollection({
  items: periods.map((item) => ({
    value: item,
    label: item,
  })),
});

export const collectionCountries = createListCollection({
  items: countries.map((country) => ({
    value: `${country.countryNameEn}`,
    label: `${country.flag} ${country.countryNameEn} +${country.countryCallingCode}`,
  })),
});

export const collectionVenues = createListCollection({
  items: venueTypes.map((item) => ({ value: item, label: item })),
});

export const collectionContactMethods = createListCollection({
  items: contactMehtods.map((item) => ({ value: item, label: item })),
});

export const collectionContactPeriods = createListCollection({
  items: contactPeriods.map((item) => ({ value: item, label: item })),
});

export const collectionRevenueTypes = createListCollection({
  items: revenueTypes.map((item) => ({ value: item, label: item })),
});

export const collectionLeadStatuses = createListCollection({
  items: leadStatuses.map((item) => ({ value: item, label: item })),
});
