import {
  Box,
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
  Flex,
  HStack,
  Separator,
  Text,
  VStack,
} from "@chakra-ui/react";
import {
  SelectContent,
  SelectItem,
  SelectRoot,
  SelectTrigger,
  SelectValueText,
} from "@/components/ui/select";
import {
  Bar,
  XAxis,
  ResponsiveContainer,
  Tooltip,
  Line,
  ComposedChart,
  TooltipProps,
} from "recharts";

import { useState, useEffect } from "react";
import { collectionPeriods, periods } from "@/lib/selectorTypes";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";

interface ConversionRateChartProps {
  leads: number[];
  subscribers: number[];
  labels: string[];
}

// Custom Tooltip Component with generics for value type and name type
const CustomTooltip = ({
  active,
  payload,
  label,
}: TooltipProps<ValueType, NameType>) => {
  if (active && payload && payload.length) {
    return (
      <Flex
        direction={"column"}
        pt={2}
        px={2}
        rounded={"md"}
        background={"#092C1C"}
        color={"white"}
        fontSize={12}
      >
        <HStack
          justify={"space-between"}
          style={{ fontSize: 16, fontWeight: "bold", marginBottom: 5 }}
        >
          <Text>{label}</Text>
          <Text>{payload[2].value}%</Text>
        </HStack>
        {/* Customize tooltip labels */}
        {[payload[0], payload[1]].map((entry: any, index: number) => (
          <HStack key={index} gap={2} style={{ marginBottom: 10 }}>
            {/* Display color box */}
            <Box w={3} h={3} bg={entry.color} rounded={"sm"} />
            {/* Display text */}
            <Text key={index} style={{ margin: 0 }}>
              {entry.dataKey === "leads" && `Leads: ${entry.value}`}
              {entry.dataKey === "subscribers" && `Subscribers: ${entry.value}`}
            </Text>
          </HStack>
        ))}
      </Flex>
    );
  }

  return null;
};

export const ConversionRateChart: React.FC<ConversionRateChartProps> = ({
  leads,
  subscribers,
  labels,
}) => {
  const [chartData, setChartData] = useState<any>(null);
  const [view, setView] = useState<string>("Month"); // State to track month or year view

  useEffect(() => {
    // Calculate conversion rates, filtering out NaN or undefined values
    const data = labels.map((label, index) => {
      const leadCount = leads[index] || 0; // Set undefined leads to 0
      const subscriberCount = subscribers[index] || 0; // Set undefined subscribers to 0
      const conversionRate =
        leadCount === 0 ? 0 : (subscribers[index] / leadCount) * 100;

      return {
        month: label,
        leads: leadCount,
        subscribers: subscriberCount,
        conversionRate: isNaN(conversionRate) ? 0 : conversionRate, // Handle NaN conversion rate
      };
    });

    setChartData(data);
  }, [leads, subscribers, labels]);

  // Effect to update the chart based on the selected view
  useEffect(() => {
    if (view === "Month") {
      //   setChartData(generateDataForCurrentMonth());
    } else {
      //   setChartData(generateDataForCurrentYear());
    }
  }, [view]);

  return (
    <Card.Root w={"100%"} height={450} rounded={"md"}>
      <CardHeader>
        <HStack justifyContent={"space-between"}>
          <VStack alignItems={"start"}>
            <CardTitle fontSize={16}>Conversion Rate</CardTitle>
            <CardDescription fontSize={12}>
              Conversion rate between new leads and subscribers
            </CardDescription>
          </VStack>
          {/* Selector to switch between Month and Year */}
          <SelectRoot
            collection={collectionPeriods}
            defaultValue={[view]}
            size="sm"
            width="200px"
            variant={"filled"}
            onValueChange={(e) => setView(e.value[0])}
          >
            <SelectTrigger>
              <SelectValueText placeholder="Select period" />
            </SelectTrigger>
            <SelectContent>
              {periods.map((period, index) => (
                <SelectItem
                  item={period}
                  key={index}
                  w={"full"}
                  cursor={"pointer"}
                >
                  {period}
                </SelectItem>
              ))}
            </SelectContent>
          </SelectRoot>
        </HStack>
      </CardHeader>
      <Card.Body>
        <Separator />
        <ResponsiveContainer>
          <ComposedChart data={chartData}>
            <XAxis
              dataKey="month"
              tickLine={false}
              tickMargin={10}
              axisLine={false}
              tickFormatter={(value) => value.slice(0, 3)}
              style={{
                fontSize: 12,
              }}
            />
            {/* Use custom tooltip */}
            <Tooltip content={<CustomTooltip />} />
            <Bar
              dataKey="leads"
              label="Leads"
              fill="#C7EA46"
              width={10}
              radius={3}
            />
            <Bar
              dataKey="subscribers"
              label="Subscribers"
              fill="#275347"
              radius={3}
            />
            <Line
              type="monotone"
              dataKey="conversionRate"
              label="Conversion Rate"
              stroke="gray"
            />
          </ComposedChart>
        </ResponsiveContainer>
      </Card.Body>
    </Card.Root>
  );
};
