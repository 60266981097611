import { createSystem, defaultConfig } from "@chakra-ui/react";

export const system = createSystem(defaultConfig, {
  theme: {
    breakpoints: {
      base: "0em", // 0px
      sm: "30em", // ~480px. em is a relative unit and is dependant on the font-size.
      md: "48em", // ~768px
      lg: "62em", // ~992px
      xl: "80em", // ~1280px
      "2xl": "96em", // ~1536px
    },
    tokens: {
      colors: {
        brand: {
          50: { value: "#ddf290" },
          100: { value: "#d8f07e" },
          200: { value: "#d2ee6b" },
          300: { value: "#d2ee6b" },
          400: { value: "#cdec59" },
          500: { value: "#C7EA46" },
          600: { value: "#b3d33f" },
          700: { value: "#9fbb38" },
          800: { value: "#8ba431" },
          900: { value: "#778c2a" },
        },
      },
      fonts: {
        body: { value: "system-ui, sans-serif" },
      },
    },
  },
});
