import { gql } from "@apollo/client";

export const REGISTER_NEW_DEVICE = gql`
  mutation RegisterNewDevice(
    $deviceId: ID!
    $os: String!
    $version: String!
    $browser: String
    $token: String
  ) {
    registerNewDevice(
      device_id: $deviceId
      os: $os
      version: $version
      browser: $browser
      token: $token
    ) {
      message
    }
  }
`;
