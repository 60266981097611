import { v4 as uuidv4 } from "uuid";

export function setAuthToken(token: string) {
  if (typeof window !== "undefined") {
    localStorage.setItem("dsk_auth_token", token);
  }
}

export function getAuthToken() {
  if (typeof window !== "undefined") {
    return localStorage.getItem("dsk_auth_token");
  }
  return null;
}

export function clearAuthToken() {
  if (typeof window !== "undefined") {
    localStorage.removeItem("dsk_auth_token");
  }
}

// Function to get or generate a device ID
export const getOrCreateDeviceId = () => {
  let deviceId = localStorage.getItem("device_id");

  if (!deviceId) {
    // Generate a new device ID (UUID)
    deviceId = uuidv4();
    localStorage.setItem("device_id", deviceId); // Store in localStorage for persistence
  }

  return deviceId;
};

export const clearDeviceId = () => {
  if (typeof window !== "undefined") {
    localStorage.removeItem("device_id");
  }
};

// Function to convert file to base64
const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file); // Read file as data URL (base64)
    reader.onload = () => resolve(reader.result); // Resolve the base64 string
    reader.onerror = (error) => reject(error); // Handle any errors
  });
};

export const uploadFileToS3 = async (file: File) => {
  try {
    // Convert file to base64
    const base64File = await getBase64(file);

    // Request presigned URL from API
    const res = await fetch("/api/aws-presigned-url", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fileName: file.name,
        type: "base64",
        data: base64File,
      }),
    });

    const { url } = await res.json();

    if (url) {
      return url; // Return URL without query params
    } else {
      console.error("Error uploading file:");
    }
  } catch (err) {
    console.error("Error uploading file:", err);
  }
};
