import { DrawerBackdrop, HStack } from "@chakra-ui/react";
import { DrawerBody, DrawerContent, DrawerRoot } from "@/components/ui/drawer";
import { SideNavBar } from "./SideNavBar";

export const MenuDrawer = ({ isMenuDrawerOpen, setIsMenuDrawerOpen }) => {
  return (
    <HStack wrap="wrap">
      <DrawerRoot
        placement="start"
        open={isMenuDrawerOpen}
        closeOnInteractOutside
        closeOnEscape
        onOpenChange={(e) => setIsMenuDrawerOpen(e.open)}
      >
        <DrawerBackdrop />
        <DrawerContent p={0} backgroundColor={"#092C1C"} shadow={"none"}>
          <DrawerBody p={0}>
            <SideNavBar />
          </DrawerBody>
        </DrawerContent>
      </DrawerRoot>
    </HStack>
  );
};
