import {
  Box,
  Text,
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
  Flex,
  HStack,
  VStack,
  createListCollection,
  Separator,
} from "@chakra-ui/react";
import {
  SelectContent,
  SelectItem,
  SelectRoot,
  SelectTrigger,
  SelectValueText,
} from "@/components/ui/select";
import { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  TooltipProps,
  ComposedChart,
} from "recharts";
import { periods } from "@/lib/selectorTypes";
import {
  ValueType,
  NameType,
} from "recharts/types/component/DefaultTooltipContent";
import { currencyConverter } from "@/lib/utils";

// Custom Tooltip Component with generics for value type and name type
const CustomTooltip = ({
  active,
  payload,
  label,
}: TooltipProps<ValueType, NameType>) => {
  if (active && payload && payload.length) {
    return (
      <Flex
        direction={"column"}
        pt={2}
        px={2}
        rounded={"md"}
        background={"#092C1C"}
        color={"white"}
        fontSize={12}
      >
        <HStack
          justify={"space-between"}
          style={{ fontSize: 16, fontWeight: "bold", marginBottom: 5 }}
        >
          <Text>{label}</Text>
        </HStack>
        {/* Customize tooltip labels */}
        {payload.map((entry: any, index: number) => (
          <HStack key={index} gap={2} style={{ marginBottom: 10 }}>
            {/* Display color box */}
            <Box w={3} h={3} bg={entry.color} rounded={"sm"} />
            {/* Display text */}
            <Text key={index} style={{ margin: 0 }}>
              {entry.dataKey === "fees" &&
                `Fees: ${currencyConverter("EUR", entry.value)}`}
              {entry.dataKey === "subscriptions" &&
                `Subscriptions: ${currencyConverter("EUR", entry.value)}`}
            </Text>
          </HStack>
        ))}
      </Flex>
    );
  }

  return null;
};

export const CustomBarChart = () => {
  const [chartData, setChartData] = useState<any>(null);
  const [view, setView] = useState<string>("Month");

  // Function to generate data for the current month
  const generateDataForCurrentMonth = () => {
    const daysInMonth = new Date(
      new Date().getFullYear(),
      new Date().getMonth() + 1,
      0
    ).getDate();
    const labels = Array.from({ length: daysInMonth }, (_, i) => i + 1);

    const data = labels.map((label) => ({
      name: label,
      subscriptions: Math.floor(Math.random() * 50000),
      fees: Math.floor(Math.random() * 50000),
    }));

    return data;
  };

  // Function to generate data for the current year
  const generateDataForCurrentYear = () => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const data = months.map((month) => ({
      name: month,
      subscriptions: Math.floor(Math.random() * 500000),
      fees: Math.floor(Math.random() * 500000),
    }));

    return data;
  };

  // Effect to update the chart based on the selected view
  useEffect(() => {
    if (view === "Month") {
      setChartData(generateDataForCurrentMonth());
    } else {
      setChartData(generateDataForCurrentYear());
    }
  }, [view]);

  const collection = createListCollection({
    items: periods.map((item) => ({ value: item, label: item })),
  });

  return (
    <Card.Root w={"100%"} height={450} rounded={"md"}>
      <CardHeader>
        <HStack justifyContent={"space-between"}>
          <VStack alignItems={"start"}>
            <CardTitle fontSize={16}>Total Revenues</CardTitle>
            <CardDescription fontSize={12}>
              Total revenues based on fees and subscriptions
            </CardDescription>
          </VStack>
          {/* Selector to switch between Month and Year */}
          <SelectRoot
            collection={collection}
            defaultValue={[view]}
            size="sm"
            width="200px"
            variant={"filled"}
            onValueChange={(e) => setView(e.value[0])}
          >
            <SelectTrigger>
              <SelectValueText placeholder="Select period" />
            </SelectTrigger>
            <SelectContent>
              {periods.map((period, index) => (
                <SelectItem
                  item={period}
                  key={index}
                  w={"full"}
                  cursor={"pointer"}
                >
                  {period}
                </SelectItem>
              ))}
            </SelectContent>
          </SelectRoot>
        </HStack>
      </CardHeader>

      <Card.Body>
        <Separator />
        {chartData && (
          <ResponsiveContainer>
            <ComposedChart
              data={chartData}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <XAxis
                dataKey="name"
                tickLine={false}
                tickMargin={10}
                axisLine={false}
                tickFormatter={(value) =>
                  view === "month" ? value.slice(0, 3) : value
                }
                style={{
                  fontSize: 12,
                }}
              />

              {/* Use custom tooltip */}
              <Tooltip content={<CustomTooltip />} />

              <Bar
                dataKey="subscriptions"
                stackId="a"
                fill="#275347"
                label="subscriptions"
              />
              <Bar label="fees" dataKey="fees" stackId="a" fill="#C7EA46" />
            </ComposedChart>
          </ResponsiveContainer>
        )}
      </Card.Body>
    </Card.Root>
  );
};
