import { Box, Flex, HStack } from "@chakra-ui/react";
import Image from "next/image";
import { IoApps } from "react-icons/io5";

export const TopNavBar = ({ setIsMenuDrawerOpen }) => {
  return (
    <Flex
      position="fixed" // Keep the sidebar fixed
      h="80px" // Full height of the viewport
      w="100%"
      py="25px"
      mt={process.env.NODE_ENV === "development" ? 10 : 0}
      backgroundColor={"#092C1C"}
      color={"white"}
      overflowY="auto" // Allow scrolling inside the sidebar
      zIndex={2} // Ensure it stays on top
      pt={"0px"}
      display={{ base: "block", md: "none" }}
    >
      <HStack justify={"space-between"}>
        <Box p={"20px"}>
          <Image
            src="/images/logo_small.svg"
            width={40}
            height={40}
            alt="logo"
          />
        </Box>
        <Box
          p={"20px"}
          cursor={"pointer"}
          onClick={() => setIsMenuDrawerOpen(true)}
        >
          <IoApps color="white" size={"30px"} />
        </Box>
      </HStack>
    </Flex>
  );
};
