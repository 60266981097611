import type { AppProps } from "next/app";
import { Provider } from "@/components/ui/provider";
import { createContext, useEffect, useState } from "react";
import { ApolloProvider } from "@apollo/client";
import { initializeApollo } from "@/apollo/client";
import "@/styles/styles.scss";
import AuthWrapper from "@/services/Auth/AuthWrapper";
import { ID } from "graphql-ws";
import { Toaster, toaster } from "@/components/ui/toaster";
import { useRouter } from "next/router";

type Notification = {
  title: String;
  description: String;
  type: "success" | "error" | "warning" | "info";
  duration?: Number;
};

type GlobalDataType = {
  user?: {
    id: ID;
    role: String;
  };
  notification?: Notification | null;
  newLead?: string | string[] | undefined;
};

type GlobalContextType = [
  GlobalDataType | null,
  React.Dispatch<React.SetStateAction<GlobalDataType | null>>
];

export const GlobalContext = createContext<GlobalContextType>([
  null,
  () => null,
]);

const App = ({ Component, pageProps }: AppProps) => {
  const [globalData, setGlobalData] = useState<GlobalDataType | null>(null);

  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url) => {
      // Store the current page in sessionStorage before navigation
      sessionStorage.setItem("pPage", router.pathname);
    };

    // Listen for route changes
    router.events.on("routeChangeStart", handleRouteChange);

    // Clean up event listener on component unmount
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router.pathname]);

  // Function to load state from localStorage on first render
  useEffect(() => {
    const storedState = localStorage.getItem("globalState");
    if (storedState) {
      setGlobalData(JSON.parse(storedState));
    }
  }, []);

  // Save state to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("globalState", JSON.stringify(globalData));
  }, [globalData]);

  useEffect(() => {
    if (globalData?.notification) {
      toaster.create({
        title: globalData.notification.title,
        description: globalData.notification.description,
        type: globalData.notification.type,
        duration: 5000,
        onStatusChange({ status }: { status: string }) {
          if (status === "unmounted") {
            setGlobalData({
              ...globalData,
              notification: null,
            });
          }
        },
      });
    }
  }, [globalData]);

  let apolloClient = initializeApollo();

  return (
    <ApolloProvider client={apolloClient}>
      <Provider>
        <AuthWrapper>
          <GlobalContext.Provider value={[globalData, setGlobalData]}>
            <Component {...pageProps} />
            <Toaster />
          </GlobalContext.Provider>
        </AuthWrapper>
      </Provider>
    </ApolloProvider>
  );
};

App.getInitialProps = async () => {
  return { data: "Hello World" };
};

export default App;
