import { Flex, Text } from "@chakra-ui/react";
import { useColorMode } from "../ui/color-mode";
import { useEffect, useState } from "react";

export const DevelopmentBar = () => {
  const [currentColorMode, setColorMode] = useState("light");

  const { colorMode } = useColorMode();

  useEffect(() => {
    if (colorMode) {
      setColorMode(colorMode);
    }
  }, [colorMode]);

  return (
    <Flex
      width={"100%"}
      height={"40px"}
      backgroundColor={currentColorMode === "light" ? "#092C1C" : "white"}
      alignItems={"center"}
      justifyContent={"center"}
      position={"fixed"}
      zIndex={3}
    >
      <Text color={currentColorMode === "light" ? "white" : "#092C1C"}>
        Development Mode
      </Text>
    </Flex>
  );
};
