import { useEffect } from "react";
import { useRouter } from "next/router";
import { isAuthenticated } from "./AuthService";
import React from "react";

interface AuthWrapperProps {
  children: React.ReactNode;
}

const AuthWrapper: React.FC<AuthWrapperProps> = ({ children }) => {
  const router = useRouter();

  useEffect(() => {
    const checkAuthentication = async () => {
      const authenticated = await isAuthenticated();

      if (!authenticated && !router.pathname.includes("auth")) {
        router.push("/auth/login"); // Redirect to login page
      } else if (!authenticated && router.pathname.includes("auth")) {
        // Do nothing
      } else if (authenticated && router.pathname.includes("auth")) {
        router.push("/"); // Redirect to home page
      }
    };

    checkAuthentication(); // Call the async function
  }, [router]);

  return <>{children}</>;
};

export default AuthWrapper;

// Higher-order component (HOC) that wraps a component with AuthWrapper
export const withAuth = <P extends object>(
  WrappedComponent: React.ComponentType<P>
) => {
  const WithAuth: React.FC<P> = (props) => (
    <AuthWrapper>
      <WrappedComponent {...props} />
    </AuthWrapper>
  );

  return WithAuth;
};
