import { Currency, currencyConverter } from "@/lib/utils";
import { Box, Flex, HStack, Separator, Text, VStack } from "@chakra-ui/react";
import Image from "next/image";

// Define the props interface
interface DataItem {
  name: string;
  value: number;
  image: string;
  currency?: Currency;
}

interface TopListProps {
  title: string;
  type: string;
  data: DataItem[]; // Define data as an array of DataItem
}

export const TopList: React.FC<TopListProps> = ({ title, data }) => {
  return (
    <Flex
      minW={300}
      maxW={500}
      h="100%"
      borderWidth={1}
      rounded={"md"}
      p={3}
      pt={5}
      direction="column"
      background={"white"}
    >
      <HStack justify={"space-between"} mb={3}>
        <VStack align={"start"}>
          <Text fontWeight={"600"}>{title}</Text>
          <Text fontSize={12} color={"fg.subtle"}>
            curent month
          </Text>
        </VStack>
        <Box onClick={() => null} cursor={"pointer"} alignSelf={"end"}>
          <Text textDecoration={"underline"} fontSize={12} color={"fg.subtle"}>
            View All
          </Text>
        </Box>
      </HStack>
      <Separator my={4} />
      {data.map((item, index) => (
        <HStack pt={2} key={index} justify={"space-between"}>
          <HStack alignItems={"center"}>
            <Image src={item.image} width={25} height={25} alt="img" />
            <Text>{item.name}</Text>
          </HStack>
          <Text>
            {item.currency
              ? currencyConverter(item.currency, item.value)
              : item.value}
          </Text>
        </HStack>
      ))}
    </Flex>
  );
};
