import { Currency } from "@/lib/utils";
import { HStack } from "@chakra-ui/react";
import {
  StatDownTrend,
  StatHelpText,
  StatLabel,
  StatRoot,
  StatUpTrend,
  StatValueText,
} from "@/components/ui/stat";

export const Stats = ({
  title,
  amount,
  currency,
  type,
  percentage,
}: {
  title: string;
  amount: number;
  currency?: Currency | null;
  type?: string | null;
  percentage: number;
}) => {
  return (
    <StatRoot background={"white"} borderWidth={1} p={2} rounded={"md"}>
      <StatLabel>{title}</StatLabel>
      <HStack>
        <StatValueText
          value={currency ? amount / 100 : amount}
          formatOptions={
            currency ? { style: "currency", currency: currency } : {}
          }
        />
        {type === "uptrending" ? (
          <StatUpTrend>{percentage}%</StatUpTrend>
        ) : (
          <StatDownTrend>{percentage}%</StatDownTrend>
        )}
      </HStack>
      <HStack justify={"space-between"}>
        <StatHelpText>since last month</StatHelpText>
        <StatHelpText cursor={"pointer"} textDecoration={"underline"}>
          View Report
        </StatHelpText>
      </HStack>
    </StatRoot>
  );
};
