import { Flex, FlexProps } from "@chakra-ui/react";
import { ReactNode, useState } from "react";
import { DevelopmentBar } from "../DevBar";
import { SideNavBar, TopNavBar, MenuDrawer } from "../Navigators";
import React from "react";

interface LayoutProps extends FlexProps {
  children: ReactNode;
}

export const Layout = ({ children, ...rest }: LayoutProps) => {
  const [isMenuDrawerOpen, setIsMenuDrawerOpen] = useState(false);
  return (
    <>
      {process.env.NODE_ENV === "development" && <DevelopmentBar />}

      <Flex width={"full"}>
        <Flex display={{ base: "none", sm: "none", md: "block" }}>
          <SideNavBar />
        </Flex>
        <Flex display={{ base: "block", sm: "block", md: "none" }}>
          <TopNavBar
            setIsMenuDrawerOpen={(value) => setIsMenuDrawerOpen(value)}
          />
          <MenuDrawer
            isMenuDrawerOpen={isMenuDrawerOpen}
            setIsMenuDrawerOpen={(value) => setIsMenuDrawerOpen(value)}
          />
        </Flex>
        <Flex
          ml={{ base: "0px", sm: "0px", md: "200px" }}
          width={{ md: "calc(100% - 200px)" }}
          direction="column"
          {...rest}
        >
          {children}
        </Flex>
      </Flex>
    </>
  );
};
