import {
  Box,
  Button,
  Flex,
  HStack,
  List,
  Text,
  useBreakpoint,
} from "@chakra-ui/react";
import { useRouter } from "next/router";
import Image from "next/image";
import { useContext } from "react";
import {
  IoGrid,
  IoBookmarkSharp,
  IoStatsChart,
  IoLogoBuffer,
  IoStorefrontSharp,
  IoPeopleSharp,
  IoSparkles,
  IoIdCard,
  IoLogOut,
} from "react-icons/io5";
import { LOGOUT_EMPLOYEE } from "@/apollo/mutations/LogoutEmployee";
import { clearAuthToken, getOrCreateDeviceId } from "@/services/utils";
import { useApolloClient } from "@apollo/client";
import { GlobalContext } from "@/pages/_app";

const items = [
  {
    id: 1,
    title: "Overview",
    icon: <IoGrid size={"20px"} />,
    url: "",
    roles: ["admin", "manager", "employee"],
  },
  // {
  //   id: 2,
  //   title: "Statistics",
  //   icon: <IoStatsChart size={"20px"} />,
  //   url: "statistics",
  //   roles: ["admin", "manager", "employee"],
  // },
  {
    id: 4,
    title: "Transactions",
    icon: <IoLogoBuffer size={"20px"} />,
    url: "transactions",
    roles: ["admin", "manager", "employee"],
  },
  {
    id: 5,
    title: "Leads",
    icon: <IoBookmarkSharp size={"20px"} />,
    url: "leads",
    roles: ["admin", "manager", "employee"],
  },
  {
    id: 6,
    title: "Venues",
    icon: <IoStorefrontSharp size={"20px"} />,
    url: "venues",
    roles: ["admin", "manager", "employee"],
  },
  {
    id: 7,
    title: "Employees",
    icon: <IoPeopleSharp size={"20px"} />,
    url: "employees",
    roles: ["admin", "manager", "employee"],
  },
  {
    id: 8,
    title: "Subscription Plans",
    icon: <IoSparkles size={"20px"} />,
    url: "plans",
    roles: ["admin", "manager", "employee"],
  },
  {
    id: 9,
    title: "Account & Settings",
    icon: <IoIdCard size={"20px"} />,
    url: "account",
    roles: ["admin", "manager", "employee"],
  },
];

const role = "admin";

export const SideNavBar = () => {
  const [globalData, setGlobalData] = useContext(GlobalContext);

  const apolloClient = useApolloClient();

  const router = useRouter();
  const current = router.asPath.substring(1);

  const onLogOut = async () => {
    try {
      const deviceId = getOrCreateDeviceId();

      await apolloClient.mutate({
        mutation: LOGOUT_EMPLOYEE,
        variables: {
          deviceId: deviceId,
        },
        context: {
          client: "deskadora",
        },
      });

      // Remove user from global context
      setGlobalData({
        ...globalData,
        user: null,
      });

      // Remove token from localStorage
      clearAuthToken();

      // Go to login page
      router.push("/auth/login");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Flex
      direction={"column"}
      position="fixed" // Keep the sidebar fixed
      h="100vh" // Full height of the viewport
      w={{ base: "300px", sm: "300px", md: "200px" }}
      py="25px"
      mt={process.env.NODE_ENV === "development" ? 10 : 0}
      backgroundColor={"#092C1C"}
      color={"white"}
      overflowY="auto" // Allow scrolling inside the sidebar
      zIndex={2} // Ensure it stays on top
      pt={"0px"}
    >
      <Box p={"20px"}>
        <Image src="/images/logo_small.svg" width={40} height={40} alt="logo" />
      </Box>
      <List.Root p={0} width={"100%"} mt={"30px"} variant="plain">
        {items
          .filter((item) => item.roles.includes(role))
          .map((item) => (
            <Box key={item.id}>
              <List.Item py={1}>
                <Box
                  fontWeight={"medium"}
                  w={"180px"}
                  p={2}
                  px={4}
                  cursor={"pointer"}
                  onClick={() => router.push(`/${item.url}`)}
                  borderLeftWidth={4}
                  borderColor={current === item.url ? "#C7EA46" : "transparent"}
                >
                  <HStack>
                    <Box color={current === item.url ? "#C7EA46" : "gray.300"}>
                      {item.icon}
                    </Box>
                    <Text
                      fontSize={12}
                      color={current === item.url ? "white" : "gray.300"}
                      fontWeight={current === item.url ? "500" : "200"}
                      textAlign={"center"}
                    >
                      {item.title}
                    </Text>
                  </HStack>
                </Box>
              </List.Item>
            </Box>
          ))}
      </List.Root>
      <Flex px={2.5} bottom={3} position={"fixed"} shadow={"md"}>
        <Button
          fontWeight={"medium"}
          p={1}
          w={{ base: "300px", sm: "300px", md: "180px" }}
          rounded={"md"}
          cursor={"pointer"}
          onClick={() => onLogOut()}
          borderLeftWidth={4}
          borderColor={"transparent"}
          background={"#275347"}
          _hover={{
            background: "#205b4a",
          }}
          _focus={{
            outline: "none",
          }}
        >
          <HStack w={"100%"}>
            <Box color={"white"}>
              <IoLogOut size={22} />
            </Box>
            <Text
              fontSize={12}
              color={"white"}
              fontWeight={"400"}
              textAlign={"center"}
            >
              Log Out
            </Text>
          </HStack>
        </Button>
      </Flex>
    </Flex>
  );
};
