import { REGISTER_NEW_DEVICE } from "@/apollo/mutations/RegisterNewDevice";
import { Layout } from "@/components/Layout/Index";
import {
  Stats,
  TopList,
  ConversionRateChart,
  CustomBarChart as BarChart,
} from "@/components/Partials";
import { Currency } from "@/lib/utils";
import { getOrCreateDeviceId } from "@/services/utils";
import { useApolloClient } from "@apollo/client";
import { Flex, Stack } from "@chakra-ui/react";
import platform from "platform";
import { useContext, useEffect } from "react";
import { GlobalContext } from "./_app";

const GLOBAL_CURRENCY: Currency = "EUR";

const MOCK_DATA = [
  {
    id: 0,
    title: "Total Gross",
    amount: 2002200,
    currency: GLOBAL_CURRENCY,
    type: "uptrending",
    percentage: 12,
  },
  {
    id: 3,
    title: "New Subscribers",
    amount: 12,
    currency: null,
    type: "uptrending",
    percentage: 100,
  },
  {
    id: 4,
    title: "New Leads",
    amount: 14,
    currency: null,
    type: null,
    percentage: 6,
  },
  {
    id: 5,
    title: "New Customers",
    amount: 1554,
    currency: null,
    type: "uptrending",
    percentage: 63,
  },
];

// Sample data for the leads and subscribers
const leads = [100, 150, 80, 120, 200]; // Example leads data
const subscribers = [50, 60, 30, 90, 120]; // Example subscribers data
const labels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "Jun",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]; // X-axis labels

const Home = () => {
  const [globalData, setGlobalData] = useContext(GlobalContext);

  const apolloClient = useApolloClient();

  useEffect(() => {
    if (globalData && globalData.user) {
      regiserDevice();
    }
  }, [globalData]);

  const regiserDevice = async () => {
    const deviceId = getOrCreateDeviceId();

    const previousPage = sessionStorage.getItem("pPage");

    if (previousPage === "/auth/login") {
      try {
        await apolloClient.mutate({
          mutation: REGISTER_NEW_DEVICE,
          variables: {
            employeeId: globalData.user.id,
            deviceId: deviceId,
            os: platform.os.family,
            version: platform.os.version,
            browser: platform.name,
          },
          context: {
            client: "deskadora",
          },
        });

        // Remove previous page from localStorage
        sessionStorage.removeItem("pPage");
      } catch (error) {
        setGlobalData({
          ...globalData,
          notification: {
            title: "Error",
            description: error ? error.message : "There was an errror",
            type: "error",
          },
        });
      }
    }
  };

  return (
    <Layout>
      <Flex
        direction={"column"}
        mt={process.env.NODE_ENV === "development" ? 10 + 25 : 20}
        p={"20px"}
      >
        <Flex direction={"column"} gap={10}>
          <Stack
            direction={{ base: "column", sm: "column", md: "row" }}
            w={"100%"}
            gap={4}
          >
            {MOCK_DATA.map((item, index) => (
              <Stats
                key={index}
                title={item.title}
                amount={item.amount}
                currency={item.currency}
                type={item.type}
                percentage={item.percentage}
              />
            ))}
          </Stack>
          <Flex
            direction={{
              base: "column",
              sm: "column",
              md: "column",
              lg: "column",
              xl: "row",
            }}
            gap={4}
          >
            <BarChart />
            <Stack
              direction={{
                base: "column",
                sm: "column",
                md: "column",
                lg: "row",
              }}
            >
              <TopList
                title="Top Countries"
                type="countries"
                data={[
                  {
                    image: "https://flagsapi.com/FR/flat/64.png",
                    name: "France",
                    value: 120,
                  },
                  {
                    image: "https://flagsapi.com/GB/flat/64.png",
                    name: "United Kingdom",
                    value: 99,
                  },
                  {
                    image: "https://flagsapi.com/IT/flat/64.png",
                    name: "Italy",
                    value: 57,
                  },
                  {
                    image: "https://flagsapi.com/BE/flat/64.png",
                    name: "Belgium",
                    value: 42,
                  },
                  {
                    image: "https://flagsapi.com/RO/flat/64.png",
                    name: "Romania",
                    value: 20,
                  },
                ]}
              />
              <TopList
                title="Top Venues"
                type="venues"
                data={[
                  {
                    image: "https://flagsapi.com/FR/flat/64.png",
                    name: "La Pinede",
                    value: 1100120,
                    currency: "EUR",
                  },
                  {
                    image: "https://flagsapi.com/GB/flat/64.png",
                    name: "Novikov",
                    value: 900009,
                    currency: "EUR",
                  },
                  {
                    image: "https://flagsapi.com/IT/flat/64.png",
                    name: "Soho Restaurant",
                    value: 570000,
                    currency: "EUR",
                  },
                  {
                    image: "https://flagsapi.com/BE/flat/64.png",
                    name: "Koya",
                    value: 42000,
                    currency: "EUR",
                  },
                  {
                    image: "https://flagsapi.com/RO/flat/64.png",
                    name: "Oscar",
                    value: 12520,
                    currency: "EUR",
                  },
                ]}
              />
            </Stack>
          </Flex>
          <Flex
            direction={{
              base: "column",
              sm: "column",
              md: "column",
              lg: "column",
              xl: "row",
            }}
            gap={4}
          >
            <Stack
              direction={{
                base: "column",
                sm: "column",
                md: "column",
                lg: "row",
              }}
            >
              <TopList
                title="Top Countries"
                type="countries"
                data={[
                  {
                    image: "https://flagsapi.com/FR/flat/64.png",
                    name: "France",
                    value: 120,
                  },
                  {
                    image: "https://flagsapi.com/GB/flat/64.png",
                    name: "United Kingdom",
                    value: 99,
                  },
                  {
                    image: "https://flagsapi.com/IT/flat/64.png",
                    name: "Italy",
                    value: 57,
                  },
                  {
                    image: "https://flagsapi.com/BE/flat/64.png",
                    name: "Belgium",
                    value: 42,
                  },
                  {
                    image: "https://flagsapi.com/RO/flat/64.png",
                    name: "Romania",
                    value: 20,
                  },
                ]}
              />
              <TopList
                title="Top Venues"
                type="venues"
                data={[
                  {
                    image: "https://flagsapi.com/FR/flat/64.png",
                    name: "La Pinede",
                    value: 1100120,
                    currency: "EUR",
                  },
                  {
                    image: "https://flagsapi.com/GB/flat/64.png",
                    name: "Novikov",
                    value: 900009,
                    currency: "EUR",
                  },
                  {
                    image: "https://flagsapi.com/IT/flat/64.png",
                    name: "Soho Restaurant",
                    value: 570000,
                    currency: "EUR",
                  },
                  {
                    image: "https://flagsapi.com/BE/flat/64.png",
                    name: "Koya",
                    value: 42000,
                    currency: "EUR",
                  },
                  {
                    image: "https://flagsapi.com/RO/flat/64.png",
                    name: "Oscar",
                    value: 12520,
                    currency: "EUR",
                  },
                ]}
              />
            </Stack>
            <ConversionRateChart
              leads={leads}
              subscribers={subscribers}
              labels={labels}
            />
          </Flex>
        </Flex>
      </Flex>
    </Layout>
  );
};

export default Home;
