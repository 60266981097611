export type Currency =
  | "GBP"
  | "USD"
  | "EUR"
  | "JPY"
  | "INR"
  | "AUD"
  | "CAD"
  | "CNY"
  | "KRW";

export const currencyConverter = (
  currency: Currency,
  amount: number
): string => {
  const currencySymbols: Record<Currency, string> = {
    GBP: "£",
    USD: "$",
    EUR: "€",
    JPY: "¥",
    INR: "₹",
    AUD: "A$",
    CAD: "C$",
    CNY: "¥",
    KRW: "₩",
  };

  // Fallback symbol in case the currency is not in the list
  const symbol = currencySymbols[currency] || "";

  // Format large numbers intelligently
  const formatLargeNumber = (amt: number): string => {
    if (amt >= 1e9) {
      return `${(amt / 1e9).toFixed(2)}B`; // Billion
    } else if (amt >= 1e6) {
      return `${(amt / 1e6).toFixed(2)}M`; // Million
    } else if (amt >= 1e3) {
      return `${(amt / 1e3).toFixed(2)}K`; // Thousand
    }
    return amt.toFixed(2); // Regular amount
  };

  // Format amount with localized separators
  const formattedAmount = formatLargeNumber(amount / 100);

  // Return the formatted currency string
  return `${symbol}${formattedAmount}`;
};

export const passwordStrength = (password) => {
  let strength = 0;

  // Criteria for increasing password strength
  const hasLowercase = /[a-z]/.test(password);
  const hasUppercase = /[A-Z]/.test(password);
  const hasNumbers = /[0-9]/.test(password);
  const hasSpecialChars = /[!@#$%^&*(),.?":{}|<>]/.test(password);

  // Increase strength score based on criteria
  if (password.length >= 8) strength++; // Length of at least 8 characters
  if (password.length >= 12) strength++; // Length of at least 12 characters
  if (hasLowercase && hasUppercase) strength++; // Both lowercase and uppercase letters
  if (hasNumbers) strength++; // Has numbers
  if (hasSpecialChars) strength++; // Has special characters

  // Determine password strength based on score
  // if (strength <= 1) {
  //   return "Very Weak";
  // } else if (strength === 2) {
  //   return "Weak";
  // } else if (strength === 3 || strength === 4) {
  //   return "Strong";
  // } else if (strength >= 5) {
  //   return "Very Strong";
  // }

  return strength;
};
